import merge from 'lodash/merge';

import colors from './colors';
import tags from './tags';

// const breakpoints = [
//   ['phone_small', 320],
//   ['phone', 376],
//   ['phablet', 540],
//   ['tablet', 735],
//   ['desktop', 1070],
//   ['desktop_medium', 1280],
//   ['desktop_large', 1440],
// ];

// ==========================================================
// Added Variables
// ==========================================================
const breakpoints = [
  ['mobile_i6', 375],
  ['mobile_i6p', 414],
  ['tablet', 660],
  ['tablet_ipad', 768],
  ['laptop', 1000],
  ['desktop', 1200],
];

const fontSizes = {
  base: "16px",
  big:  "18px",
};

const fontWeights = {
  regular:   400,
  semibold:  600,
  bold:      700,
  extrabold: 800,
};

const lineHeights = {
  regular:   1.6,
  header:    1.3,
}
// ==========================================================
// End of added variables
// ==========================================================

const fonts = {
  serif: "'Raleway', 'Merriweather', Georgia, Serif",
  sansSerif: "'Raleway', SF Pro Display', '-apple-system', 'BlinkMacSystemFont', 'San Francisco', 'Helvetica Neue', 'Helvetica', 'Ubuntu', 'Roboto', 'Noto', 'Segoe UI', 'Arial', sans-serif",
  monospace: `"Operator Mono", Consolas, Menlo, Monaco, source-code-pro, Courier New, monospace`,
};

const colorModeTransition =
  'background 0.25s var(--ease-in-out-quad), color 0.25s var(--ease-in-out-quad)';

const transitionValue =
  '0.25s cubic-bezier(0.4, 0.01, 0.165, 0.99);';

export default merge({
  initialColorMode: 'light',
  useCustomProperties: true,
  colorModeTransition,
  transitionValue,
  colors,
  fonts,
  breakpoints,
  tags,
  fontSizes,
  fontWeights,
  lineHeights,
});
