/* eslint-disable no-console, import/no-extraneous-dependencies */
module.exports = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (typeof window.IntersectionObserver === `undefined`) {
    require(`intersection-observer`);
    console.log(`# IntersectionObserver is polyfilled!`);
  };

  // LogRocket Analytics Initialization
  const LogRocket = require(`logrocket`);
  LogRocket.init(`lkbazu/dlmr-consulting`);
  // Get Detailed Data about what Components the user clicks on
  // const setupLogRocketReact = require(`logrocket-react`);
  // setupLogRocketReact(LogRocket);
  // Log Drift interactions
  let everythingLoaded = setInterval(function() {
    if (typeof window.drift !== `undefined`) {
      clearInterval(everythingLoaded);
      LogRocket.getSessionURL(function (sessionURL) {
        console.log(window.drift);
        window.drift.track('LogRocket', { sessionURL: sessionURL });
      });
    };
  }, 100);
  // In .babelrc
  // {
  //   "presets": [ "babel-preset-gatsby" ],
  //   "plugins": [ "add-react-displayname" ]
  // }
}