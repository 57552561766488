module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"DLMR Consulting","short_name":"DLMR","description":"A legizgalmasabb vállalatok technológiai, branding és marketing partnere: Webfejlesztés, Weboldal & Webshop készítés, Applikáció fejlesztés, Keresőoptimalizálás, Online Marketing.","lang":"hu","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"standalone","icon":"src/images/logo-icon-white.png","localize":[{"start_url":"/en/","lang":"en","name":"DLMR Consulting","short_name":"DLMR","description":"Creating beautiful and enhanced digital footprints with state of the art strategy, and design that boosts your business."}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/opt/build/repo/src/intl","languages":["en","hu"],"defaultLanguage":"hu","redirect":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://dlmrconsulting.com","exclude":["/dev-404-page","/404","/404.html","/offline-plugin-app-shell-fallback"],"noHash":true,"noQueryString":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-drift/gatsby-browser.js'),
      options: {"plugins":[],"appId":"6n5gp5csnvv2"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-154519418-1","head":true,"anonymize":true,"respectDNT":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
