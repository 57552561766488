/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
// ES5 way
exports.onInitialClientRender = require('./src/gatsby/browser/onInitialClientRender');
exports.onRouteUpdate = require('./src/gatsby/browser/onRouteUpdate');
exports.shouldUpdateScroll = require('./src/gatsby/browser/shouldUpdateScroll');
exports.onClientEntry = require('./src/gatsby/browser/onClientEntry');

// ES6 way
// export const onInitialClientRender = require('./src/gatsby/browser/onInitialClientRender');
// export const onRouteUpdate = require('./src/gatsby/browser/onRouteUpdate');
// export const shouldUpdateScroll = require('./src/gatsby/browser/shouldUpdateScroll');
// export const onClientEntry = require('./src/gatsby/browser/onClientEntry');
